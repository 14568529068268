
$prefix: "bs-";

@import "./themes/assuperreisen/scss/bootstrap";


:root {
	--bs-select-padding-y: #{$form-select-padding-y}; //
	--bs-select-padding-x: #{$form-select-padding-x}; //
	--bs-select-font-size: #{$form-select-font-size}; //
	--bs-select-indicator-padding: #{$form-select-indicator-padding};
	--bs-select-font-weight: #{$form-select-font-weight}; //
	--bs-select-line-height: #{$form-select-line-height}; //
	--bs-select-color: #{$form-select-color}; //
	--bs-select-bg: #{$form-select-bg}; //
	--bs-select-disabled-color: #{$form-select-disabled-color};
	--bs-select-disabled-bg: #{$form-select-disabled-bg};
	--bs-select-disabled-border-color: #{$form-select-disabled-border-color};
	--bs-select-bg-position: #{$form-select-bg-position};
	--bs-select-bg-size: #{$form-select-bg-size};
	--bs-select-indicator: #{escape-svg($form-select-indicator)};
	--bs-select-feedback-icon-padding-end: #{$form-select-feedback-icon-padding-end};
	--bs-select-feedback-icon-position: #{$form-select-feedback-icon-position};
	--bs-select-feedback-icon-size: #{$form-select-feedback-icon-size};
	--bs-select-border-width: #{$form-select-border-width}; //
	--bs-select-border-color: #{$form-select-border-color}; //
	--bs-select-focus-border-color: #{$form-select-focus-border-color}; //
	--bs-select-padding-y-sm: #{$form-select-padding-y-sm};
	--bs-select-padding-x-sm: #{$form-select-padding-x-sm};
	--bs-select-font-size-sm: #{$form-select-font-size-sm};
	--bs-select-padding-y-lg: #{$form-select-padding-y-lg};
	--bs-select-padding-x-lg: #{$form-select-padding-x-lg};
	--bs-select-font-size-lg: #{$form-select-font-size-lg};
	@if $enable-rounded {
		--bs-select-border-radius: #{$form-select-border-radius}; //
	} @else {
		--bs-select-border-radius: 0; //
	}
}


.btn-primary {
    color: #fff;
   
}   


.btn-primary:hover {
    color: #fff;
   
}   